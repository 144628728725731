<template>
  <div class="table-home">
    <div class="col-12 text-center table-tit pt-3">VISÃO POR BIOMAS {{titleBioma.toUpperCase()}}</div>
    <b-table class="pointer" :items="items" :fields="fields" :tbody-tr-class="setTbodyTrClass" @row-clicked="rowClickHandler">
        <template #cell(color)>
            <div class="legend"></div>
        </template>
        <template #cell(percent)="row">
                <div>{{formatNumber(row.item.percent,true)}}</div>
        </template>
    </b-table>
  </div>
</template>

<script>
import api from '@/services/api.js'
import barramento from '@/barramento'

  export default {
    props: ['endpoint','data'],
    data() {
        return {
            items: [],
            itemsGeral: [],
            itemsBiomas: [],
            titleBioma:'',
            fields: [
                {
                    key: 'color',
                    label: '',
                    thStyle: {
                                width: '6%'
                            },
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'category',
                    label: 'Categorias Avaliadas',
                    thStyle: {
                                width: '35%'
                            },
                    thClass: 'text-left activeBorder',
                    tdClass: 'text-left activeBorder'
                },
                {
                    key: 'total',
                    label: 'Espécies',
                    thStyle: {
                                width: '10%',
                            },
                    thClass: 'text-left activeBorder',
                    tdClass: 'text-left activeBorder'
                },
                {
                    key: 'percent',
                    label: '%',
                    thStyle: {
                                width: '8%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left '
                },
                {
                    key: 'blank',
                    label: '',
                    thStyle: {
                                width: '5%'
                            },
                    thClass: 'text-left',
                    tdClass: 'text-left '
                }
            ],
            cat: {'EX': 127, 'EW': 128, 'RE': 129, 'CR': 130, 'EN': 131, 'VU': 132, 'NT': 133, 'LC': 134, 'DD': 135, 'NA': 136},
            bioma: {'AMAZONIA': 20, 'MATA_ATLANTICA': 24, 'PAMPA': 25, 'CAATINGA': 21, 'CERRADO': 22, 'PANTANAL': 26, 'SISTEMA_COSTEIRO_MARINHO': 23}
        }
    },
    async created() {
        var t = this
        this.itemsGeral = this.data
        this.items = this.itemsGeral

        await api.get('/graphEvaluatedCategoriesBiomes').then(response => (
            t.itemsBiomas = response.data.data
        ))
    },
    watch: {
        endpoint(){
            if(this.endpoint == ''){
                this.items = this.itemsGeral
                this.titleBioma = ''
            } else {
                // debugger; // eslint-disable-line no-debugger
                var ar = this.newArray(this.itemsBiomas[this.endpoint].categories)
                var titleBioma = " - "+this.itemsBiomas[this.endpoint].label
                this.titleBioma = titleBioma
                this.items = ar
            }
        },
        dataArr() {
            this
        }
    },
    methods: {
        setTbodyTrClass(item) {
            const classes = [];
            if(item) classes.push('custom-border');
            return classes;
        },
        newArray(array) {
            var ar = []
            for ( var i in array) {
                ar.push({categoryCode: i, category: array[i].label, total: array[i].total, percent: array[i].percent})
            }
            return ar
        },
        rowClickHandler(record){
            var catCode = record.categoryCode
            var category = record.category
            var idCat = this.cat[catCode]
            var query = '/search?categoriaIds=' + idCat

            var filtro = ''

            if(this.endpoint) {
                var idBioma = this.bioma[this.endpoint]
                query = query + '&biomaIds=' + idBioma

                filtro = this.endpoint.replace('_', ' ') + ' ' + category

                barramento.$emit('openModalR', {'query': query, 'items': {'biomaIds': [idBioma], 'categoriaIds': [idCat]}, 'filter': filtro})
            } else {
                barramento.$emit('openModalR', {'query': query, 'items': {'categoriaIds': [idCat]}, 'filter': category})
            }
        }
    }
  }
</script>

<style lang="scss">

    @import '@/web/scss/tables.scss';
    .pointer{
        cursor: pointer;
    }



</style>
